import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import '~/theme/styles.css';
import '~/config/Reactotron';

import { store } from '~/store';
import Global from '~/theme/global';
import history from '~/services/history';

import Routes from '~/routes';
import { Header, BigMenu } from '~/components';

import mockData from '~/data/abvaq';

export default function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Global />

        <BigMenu>
          <div className="body">
            <Header name={mockData.name} menuitems={mockData.menuItems} />

            <Routes />
          </div>
        </BigMenu>
      </Router>
    </Provider>
  );
}
