import produce from 'immer';

const INITIAL_STATE = {
  information: {},
  loading: false,
  error: false,
};

export default function metadata(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@metadata/REQUEST':
        draft.loading = true;
        draft.error = false;
        break;

      case '@metadata/SUCCESS':
        draft.information = action.payload;
        draft.loading = false;
        draft.error = false;
        break;

      case '@metadata/FAILURE':
        draft.loading = false;
        draft.error = true;
        break;

      default:
        return state;
    }
  });
}
