import { createGlobalStyle } from 'styled-components';
import media from 'styled-media-query';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import colors from './colors';

export default createGlobalStyle`
  @font-face {
    font-family: 'GoogleSans-Regular';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/Regular/GoogleSans-Regular.ttf');
    src: local('Google Sans'), local('GoogleSans'),
      url('../assets/fonts/Regular/GoogleSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Regular/GoogleSans-Regular.woff2') format('woff2'),
      url('../assets/fonts/Regular/GoogleSans-Regular.woff') format('woff'),
      url('../assets/fonts/Regular/GoogleSans-Regular.ttf') format('truetype'),
      url('../assets/fonts/Regular/GoogleSans-Regular.svg#GoogleSans') format('svg');
  }

  @font-face {
    font-family: 'GoogleSans-Medium';
    font-weight: normal;
    font-style: normal;
    src: url('../assets/fonts/Medium/GoogleSans-Medium.ttf');
    src: local('Google Sans'), local('GoogleSans'),
      url('../assets/fonts/Medium/GoogleSans-Medium.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Medium/GoogleSans-Medium.woff') format('woff'),
      url('../assets/fonts/Medium/GoogleSans-Medium.ttf') format('truetype'),
      url('../assets/fonts/Medium/GoogleSans-Medium.svg#GoogleSans') format('svg');
  }

  @font-face {
    font-family: 'GoogleSans-Bold';
    font-style: bold;
    font-weight: 700;
    src: url('../assets/fonts/Bold/GoogleSans-Bold.ttf');
    src: local('Google Sans'), local('GoogleSans'),
      url('../assets/fonts/Bold/GoogleSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Bold/GoogleSans-Bold.woff') format('woff'),
      url('../assets/fonts/Bold/GoogleSans-Bold.ttf') format('truetype'),
      url('../assets/fonts/Bold/GoogleSans-Bold.svg#GoogleSans') format('svg');
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

    transition: .3s;
  }

  *:focus {
    outline: 0;
  }

  html {
    overflow: hidden;
    height: 100%;
  }

  body, #root {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
  }

  body {
    -webkit-font-smoothing: antialiased;

    ${media.lessThan('large')`
      background-color: ${colors.primary};
    `}
  }

  body, input, button {
    font: 16px 'GoogleSans-Regular';
  }

  a {
    color: ${colors.black};
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }
`;
