import React from 'react';
import { FiSearch } from 'react-icons/fi';

import { colors } from '~/theme';

import { Container } from './styles';

export default function Input(props) {
  return (
    <Container>
      <input {...props} type="text" />

      <FiSearch size={16} color={colors.primaryLighten} />
    </Container>
  );
}
