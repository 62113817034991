export default [
  { value: 'janeiro', label: 'Janeiro', monthNumber: '01', paramNumber: '1' },
  {
    value: 'fevereiro',
    label: 'Fevereiro',
    monthNumber: '02',
    paramNumber: '2',
  },
  { value: 'marco', label: 'Março', monthNumber: '03', paramNumber: '3' },
  { value: 'abril', label: 'Abril', monthNumber: '04', paramNumber: '4' },
  { value: 'maio', label: 'Maio', monthNumber: '05', paramNumber: '5' },
  { value: 'junho', label: 'Junho', monthNumber: '06', paramNumber: '6' },
  { value: 'julho', label: 'Julho', monthNumber: '07', paramNumber: '7' },
  { value: 'agosto', label: 'Agosto', monthNumber: '08', paramNumber: '8' },
  { value: 'setembro', label: 'Setembro', monthNumber: '09', paramNumber: '9' },
  { value: 'outubro', label: 'Outubro', monthNumber: '10', paramNumber: '10' },
  {
    value: 'novembro',
    label: 'Novembro',
    monthNumber: '11',
    paramNumber: '11',
  },
  {
    value: 'dezembro',
    label: 'Dezembro',
    monthNumber: '12',
    paramNumber: '12',
  },
];
