import styled from 'styled-components';
import media from 'styled-media-query';

import { colors, fonts, metrics } from '~/theme';

export const InformationDesktop = styled.div`
  padding: 8px 30px;
  margin-bottom: 32px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${colors.whiteGreen};

  > div {
    display: flex;
    align-items: center;

    > .wrap {
      > .title,
      > .value {
        line-height: 19px;
        letter-spacing: -0.3px;
        font-size: ${fonts.size_2};
      }

      > .title {
        margin-bottom: 4px;

        color: ${colors.black};
        font-weight: 600;
        font-family: 'GoogleSans-Medium', sans-serif;
      }

      > .value {
        font-weight: normal;
        color: ${colors.primaryLighten};
      }

      & + .wrap {
        margin-left: 56px;
      }
    }
  }

  > p {
    font-weight: 600;
    font-size: ${fonts.size_3}px;
    line-height: 1.24;
    letter-spacing: 0.34;
    color: ${colors.primaryLighten};
  }
`;

export const InformationMobile = styled.div`
  width: calc(100% + 12px);
  margin-right: -12px;
  margin-bottom: 16px;
  padding: 8px 0 8px 5px;
  border-top-left-radius: ${metrics.radius}px;
  border-bottom-left-radius: ${metrics.radius}px;
  border-left: 3px solid ${colors.primaryLighten};

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${colors.whiteGreen};

  > .left {
    > div {
      display: flex;
      align-items: flex-start;

      div + & {
        margin-top: 8px;
      }

      > i {
        height: 18px;
        margin-right: 4px;
      }

      > p {
        margin-top: 1px;
        padding-right: 12px;

        line-height: 14px;
        font-size: ${fonts.size_1}px;
        color: ${colors.primaryLighten};
      }
    }
  }

  > .right {
    min-width: 76px;
    height: 51px;
    padding: 10px 6px;
    border-top-left-radius: ${metrics.radius}px;
    border-bottom-left-radius: ${metrics.radius}px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: ${colors.whiteGreen};
    background: ${colors.primary};

    > strong {
      font-size: ${fonts.size_3}px;
    }

    > span {
      line-height: 12px;
      font-size: ${fonts.size_0}px;
    }
  }
`;

export const Content = styled.div`
  display: flex;

  > .left {
    > .ranking {
      display: none;

      > p {
        width: 160px;
        margin-bottom: 7px;

        line-height: 17px;
        text-align: center;
        color: ${colors.black};
        font-size: ${fonts.size_1}px;
      }

      > button {
        height: 40px;
        border: 0;
        border-radius: 8px;
        padding: 0 34px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

        display: flex;
        align-items: center;
        justify-content: center;

        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.85px;
        font-size: ${fonts.size_3}px;
        color: ${colors.whiteGreen};
        background: ${colors.primary};

        > svg {
          margin-right: 4px;
        }
      }
    }
  }

  ${media.lessThan('large')`
    flex-direction: column;
  `}
`;
