import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';
import pt from 'date-fns/locale/pt';
import { MdPlace } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { FaTrophy } from 'react-icons/fa';
import { IoMdRibbon } from 'react-icons/io';
import { parseISO, format } from 'date-fns';

import api from '~/services/api';
import { colors } from '~/theme';
import { money } from '~/functions';
import {
  Container,
  Title,
  Loader,
  TableAction,
  Table,
  Helmet,
} from '~/components';

import { InformationDesktop, InformationMobile, Content } from './styles';

function ResultDetail({ history, match, isMobile }) {
  const information = useSelector(state => state.metadatas.information);

  const [event, setEvent] = useState({});
  const [results, setResults] = useState({});
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeCategory, setActiveCategory] = useState({
    id: 1,
    title: 'Profissional',
  });

  function handleNormalDate(date) {
    const parsedDate = parseISO(date);

    return format(parsedDate, 'dd/MM/yyyy', { locale: pt });
  }

  function handleFormatDay(date) {
    const parsedDate = parseISO(date);

    return format(parsedDate, 'd', { locale: pt });
  }

  function handleFormatMonth(date) {
    const parsedDate = parseISO(date);
    const month = format(parsedDate, 'MMM', { locale: pt });
    const year = format(parsedDate, 'yyyy', { locale: pt });

    return `${month} ${year}`;
  }

  useEffect(() => {
    async function fetchResult(categoryId = 1) {
      setIsLoading(true);

      try {
        const eventId = match.params.id;

        const [
          eventResponse,
          classificationCategoryResponse,
          classificationResponse,
        ] = await Promise.all([
          api.get(`stages/${eventId}`),
          api.get('classifications/categories'),
          api.get('classifications', {
            params: {
              stage_id: eventId,
              classification_category_id: categoryId,
            },
          }),
        ]);

        const {
          title,
          city,
          park,
          start_date,
          finish_date,
          prize_amount,
        } = eventResponse.data;

        setEvent({
          city,
          title,
          prize_amount: money.formatMoney(prize_amount),
          desktop_date: `${handleNormalDate(start_date)} - ${handleNormalDate(
            finish_date
          )}`,
          date: `${handleFormatDay(start_date)} a ${handleFormatDay(
            finish_date
          )}`,
          weekDay: handleFormatMonth(start_date),
          place: park.title,
        });

        setCategories(classificationCategoryResponse.data);

        const classifications = classificationResponse.data.map(
          (classification, index) => ({
            id: index,
            position: index + 1,
            class: `${classification.classification}º`,
            puxador_competitor:
              classification.classifications_has_competitor[0].competitor.name,
            esteira_competitor:
              classification.classifications_has_competitor[1].competitor.name,
            points: Number(classification.points),
          })
        );

        setResults(classifications);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }

    fetchResult(activeCategory.id);
  }, [activeCategory.id, history.location.state, match.params]);

  function renderDesktop() {
    return (
      <InformationDesktop>
        <div>
          <div className="wrap">
            <p className="title">Parque:</p>
            <p className="value">{event.place}</p>
          </div>

          <div className="wrap">
            <p className="title">Cidade:</p>
            <p className="value">{event.city}</p>
          </div>

          <div className="wrap">
            <p className="title">Data:</p>
            <p className="value">{event.desktop_date}</p>
          </div>
        </div>

        <p>Premiação: {event.prize_amount}</p>
      </InformationDesktop>
    );
  }

  function renderMobile() {
    return (
      <InformationMobile>
        <div className="left">
          <div>
            <i>
              <MdPlace className="place" size={18} color={colors.primary} />
            </i>

            <p>{event.place}</p>
          </div>

          <div>
            <i>
              <IoMdRibbon size={18} color={colors.primary} />
            </i>

            <p>{event.prize_amount}</p>
          </div>
        </div>

        <div className="right">
          <strong>{event.date}</strong>
          <span>{event.weekDay}</span>
        </div>
      </InformationMobile>
    );
  }

  return (
    <Container>
      <>
        <Helmet
          title={`${String(
            information.name
          ).toUpperCase()} - Resultados Detalhes`}
        />

        <Title
          backBtn
          title={event.title}
          subtitle={`Resultados - Categoria ${activeCategory.title}`}
        />

        {isMobile ? renderMobile() : renderDesktop()}

        <Content>
          <div className="left">
            <TableAction
              categories={categories}
              activeCategory={activeCategory.title}
              changeActiveCategory={setActiveCategory}
            />

            <div className="ranking">
              <p>
                Confira o ranking <strong>profissional</strong> desta etapa
              </p>

              <button type="button">
                <FaTrophy size={18} color={colors.secondary} />
                Ranking
              </button>
            </div>
          </div>

          {isLoading ? (
            <Loader
              type="ThreeDots"
              color={colors.secondary}
              height={80}
              width={80}
            />
          ) : (
            <Table
              data={results}
              activeCategory={activeCategory.title}
              labels={['Nº', 'Class', 'Puxador', 'Esteira', 'Pontos']}
            />
          )}
        </Content>
      </>
    </Container>
  );
}

ResultDetail.propTypes = {
  match: PropTypes.shape().isRequired,
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        event: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
      }),
    }),
  }).isRequired,
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 1170,
});

export default withSizes(mapSizesToProps)(ResultDetail);
