/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import withSizes from 'react-sizes';
import PropTypes from 'prop-types';

import { Container, SelectLib } from './styles';

function Select({ options, name, type, onChange, isMobile }) {
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    function handleSelectType() {
      if (type === 'month') {
        const monthIndex = new Date().getMonth();

        return setSelectedValue(options[monthIndex]);
      }

      if (type === 'year') {
        const actualYear = String(new Date().getFullYear());

        return setSelectedValue({ label: actualYear, value: actualYear });
      }

      return false;
    }

    handleSelectType();
  }, [options, type]);

  useEffect(() => {
    onChange(selectedValue);
  }, [onChange, selectedValue]);

  function handleSelectChange(selectedOption) {
    setSelectedValue(selectedOption);
  }

  return (
    <Container>
      <label>{name}:</label>

      <SelectLib
        isMobile={isMobile}
        options={options}
        value={selectedValue}
        onChange={event => handleSelectChange(event)}
      />
    </Container>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 1170,
});

export default withSizes(mapSizesToProps)(Select);
