import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet as HelmetLib } from 'react-helmet';

export default function Helmet({ title }) {
  const information = useSelector(
    state => state.metadatas.information.metatags
  );

  return (
    <HelmetLib>
      <title>{title}</title>

      <link rel="shortcut icon" href={information && information.favicon} />

      <meta name="title" content={title} />

      <meta
        name="description"
        content={information && information.description}
      />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={information && information.url} />
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content={information && information.description}
      />
      <meta property="og:image" content={information && information.url} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={information && information.url} />
      <meta property="twitter:title" content={title} />
      <meta
        property="twitter:description"
        content={information && information.description}
      />
      <meta
        property="twitter:image"
        content={information && information.image_path}
      />
    </HelmetLib>
  );
}

Helmet.propTypes = {
  title: PropTypes.string.isRequired,
};
