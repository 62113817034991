import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';
import { useDispatch, useSelector } from 'react-redux';

import api from '~/services/api';
import months from '~/data/months';
import { date as dateFunctions } from '~/functions';
import { requestItems } from '~/store/modules/menu/action';
import { metadataRequest } from '~/store/modules/metadatas/action';
import { Title, Table, Select, Container, Helmet } from '~/components';

import { Page } from './styles';

function Main({ isMobile }) {
  const dispatch = useDispatch();
  const information = useSelector(state => state.metadatas.information);
  const client_id = 9;

  const [years, setYears] = useState([]);
  const [results, setResults] = useState([]);
  const [isParamsChange, setIsParamsChange] = useState(false);
  const [activeYear, setActiveYear] = useState(new Date().getFullYear());
  const [activeMonth, setActiveMonth] = useState(months[new Date().getMonth()]);

  const handleDataFormat = useCallback(
    date => {
      if (isMobile)
        return (date && dateFunctions.weekDayInFull(date)) || 'Sem data';

      return (date && dateFunctions.normalMaskedDate(date)) || 'Sem data';
    },
    [isMobile]
  );

  useEffect(() => {
    dispatch(metadataRequest());
    dispatch(requestItems(client_id));

    async function fetchYear() {
      if (client_id) {
        const response = await api.get('stages/years', {
          params: {
            client_id,
          },
        });

        const formatedYears = response.data
          .map(yearItem => ({
            value: yearItem,
            label: yearItem,
          }))
          .reverse();

        setYears(formatedYears);
      }
    }

    fetchYear();
  }, [client_id, dispatch]);

  useEffect(() => {
    let params = {
      client_id,
    };

    if (isParamsChange) {
      params = {
        client_id,
        start_date: `${activeYear}-${activeMonth.monthNumber}-01`,
      };
    }

    async function fetchStages() {
      const response = await api.get('stages', {
        params,
      });

      if (isMobile) {
        const events = response.data.rows.map(row => ({
          id: row.id,
          event: row.title,
          place: row.park.title,
          day:
            (row.start_date && dateFunctions.numberOfDay(row.start_date)) ||
            null,
          weekDay: (row.start_date && handleDataFormat(row.start_date)) || null,
        }));

        return setResults(events);
      }

      const events = response.data.rows.map(row => ({
        id: row.id,
        event: row.title,
        place: row.park.title,
        data: `${handleDataFormat(row.start_date)} - ${handleDataFormat(
          row.finish_date
        )}`,
      }));

      return setResults(events);
    }

    fetchStages();
  }, [
    handleDataFormat,
    isMobile,
    activeMonth,
    activeYear,
    isParamsChange,
    client_id,
  ]);

  function handleChangeYear(selectedYear) {
    if (selectedYear && Number(activeYear) !== Number(selectedYear.value)) {
      setIsParamsChange(true);
      setActiveYear(selectedYear.value);
    }
  }

  function handleChangeMonth(selectedMonth) {
    if (
      selectedMonth &&
      Number(selectedMonth.monthNumber) !== Number(activeMonth.paramNumber)
    ) {
      setIsParamsChange(true);
      setActiveMonth(selectedMonth);
    }
  }

  return (
    <>
      <Helmet
        title={`${String(information.name).toUpperCase()} - Resultados`}
      />

      <Container>
        <Title title="Resultados" mobileHide />

        <Page>
          <div className="filters">
            <Select
              name="Mês"
              type="month"
              options={months}
              onChange={handleChangeMonth}
            />

            <Select
              name="Ano"
              type="year"
              options={years}
              onChange={handleChangeYear}
            />
          </div>

          <Table
            data={results}
            card
            links
            labels={['Evento', 'Local', 'Data']}
          />
        </Page>
      </Container>
    </>
  );
}

Main.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 1170,
});

export default withSizes(mapSizesToProps)(Main);
