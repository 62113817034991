import styled from 'styled-components';
import media from 'styled-media-query';

import { metrics } from '~/theme';

export const Wrapper = styled.section`
  position: relative;

  width: 100%;
  margin: 40px auto 10px;
  max-width: ${metrics.maxWidth}px;

  ${media.lessThan('large')`
    max-width: unset;
    margin: 20px 0 10px;
    padding: 0 ${metrics.containerPadding}px;
  `}
`;
