export default {
  name: 'abvaq',
  menuItems: [
    'inicio',
    'institucional',
    'regularmento',
    'resultados',
    'ranking',
    'notícias',
    'eventos',
    'profissionais',
    'contate-nos',
  ],
};
