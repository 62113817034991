import React from 'react';
import Loaders from 'react-loader-spinner';

import { Container } from './styles';

export default function Loader(props) {
  return (
    <Container>
      <Loaders {...props} />
    </Container>
  );
}
