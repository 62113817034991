import produce from 'immer';

const INITIAL_STATE = {
  open: false,
};

export default function menu(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@menu/OPEN':
        draft.open = action.payload.data.open;
        break;

      case '@menu/CLOSE':
        draft.open = action.payload.data.open;
        break;

      case '@menu/ACTIVE_ITEM':
        draft.content.active = action.payload.item;
        break;

      default:
        return state;
    }
  });
}
