import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';

import history from '~/services/history';

import Card from '../Card';
import Input from '../Input';
import { Container, TableItem, TableLabel } from './styles';

function Table({ data, card, labels, links, isMobile }) {
  const [rows, setRows] = useState([{}]);
  const [searchRows, setSearchRows] = useState([{}]);

  useEffect(() => {
    if (data) {
      setRows(data);
      setSearchRows(data);
    }
  }, [data]);

  function handleTextChange(event) {
    const searchString = String(event.target.value).toUpperCase();

    const filteredRows = rows.filter(row => {
      const itemsToUpper = Object.keys(row).map(item => {
        return String(row[item]).toUpperCase();
      });

      const isMatching = itemsToUpper.filter(itemUpper => {
        return itemUpper.indexOf(searchString) > -1;
      });

      return isMatching.length > 0;
    });

    setSearchRows(filteredRows);
  }

  function textHasNumberCharacter(text) {
    return String(text).includes('º');
  }

  function isNumber(label) {
    if (textHasNumberCharacter(label)) return true;

    if (typeof label === 'number') return true;

    if (label === 'Class') return true;
    if (label === 'Pontos') return true;

    return false;
  }

  function handleWeekDayFormat(weekDay) {
    return weekDay.split('-')[0];
  }

  function isTotalScore(label) {
    if (label.toLowerCase() === 'points') return true;

    return false;
  }

  function renderTable() {
    return (
      <table>
        <thead>
          <tr>
            {labels.map(label => (
              <TableLabel isNumber={isNumber(label)} key={label}>
                {label}
              </TableLabel>
            ))}
          </tr>
        </thead>

        <tbody>
          {searchRows &&
            searchRows.map(row => (
              <tr
                key={row.id}
                data-testid="rows"
                onClick={() =>
                  links &&
                  history.push({
                    pathname: `/resultados/${row.id}`,
                    state: {
                      title: row.event,
                      state: row.place,
                      city: row.city,
                    },
                  })
                }
              >
                {Object.keys(row).map(
                  item =>
                    item !== 'id' && (
                      <TableItem
                        key={item}
                        isNumber={isNumber(row[item])}
                        isTotalScore={isTotalScore(item)}
                      >
                        {row[item]}
                      </TableItem>
                    )
                )}
              </tr>
            ))}
        </tbody>
      </table>
    );
  }

  function renderCard() {
    return (
      <>
        {searchRows &&
          searchRows.map(row => (
            <Card
              day={row.day}
              id={row.id}
              key={row.id}
              weekDay={
                (row.weekDay && handleWeekDayFormat(row.weekDay)) || 'Sem data'
              }
              event={row.event}
              place={row.place}
              city={row.city}
              state={row.state}
            />
          ))}
      </>
    );
  }

  return (
    <Container>
      <Input
        data-testid="search"
        placeholder="Pesquisar"
        onChange={event => handleTextChange(event)}
      />

      {isMobile && card ? renderCard() : renderTable()}

      {data.length === 0 && <p>Nada para listar.</p>}
    </Container>
  );
}

Table.defaultProps = {
  card: false,
  links: false,
};

Table.propTypes = {
  // data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  card: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  links: PropTypes.bool,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 1170,
});

export default withSizes(mapSizesToProps)(Table);
