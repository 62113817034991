import pt from 'date-fns/locale/pt';
import { parseISO, format } from 'date-fns';

export function normalMaskedDate(date) {
  const parsedDate = parseISO(date, { locale: pt });

  return format(parsedDate, 'dd/MM/yyyy');
}

export function weekDayInFull(date) {
  const parsedDate = parseISO(date);

  return format(parsedDate, 'EEEE', { locale: pt });
}

export function numberOfDay(date) {
  const parsedDate = parseISO(date);

  return format(parsedDate, 'd');
}

export default { normalMaskedDate, weekDayInFull };
