import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

import history from '~/services/history';
import { setMenuAsOpen, setMenuAsClose } from '~/store/modules/menu/action';

import { Container, Icon, Item, Logo, Menu } from './styles';

export default function Header({ logo, menuitems }) {
  const dispatch = useDispatch();
  const menu = useSelector(state => state.menu.open);
  const metadataInfo = useSelector(state => state.metadatas.information);

  function handleMenu() {
    if (menu) return dispatch(setMenuAsClose({ open: false }));

    return dispatch(setMenuAsOpen({ open: true }));
  }

  return (
    <Container>
      <header>
        <Logo to="/">
          <img
            alt={`${metadataInfo.name} logo`}
            src={
              'https://nucleos.nyc3.digitaloceanspaces.com/abvaq/img/logo.png'
            }
          />
        </Logo>

        <ul>
          {metadataInfo.menu_items &&
            metadataInfo.menu_items.map(item => (
              <Item key={item.name}>
                <a href={item.url}>{item.prettyName}</a>
              </Item>
            ))}
        </ul>

        <div>
          <Menu onClick={handleMenu} isOpen={menu} />

          <h1>
            {(history.location.state && history.location.state.page) ||
              'Resultados'}
          </h1>

          <Icon
            name="facebook"
            target="_blank"
            href={metadataInfo.socials && metadataInfo.socials.facebook}
          >
            <FaFacebookF size={15} color="#fff" />
          </Icon>

          <Icon
            name="instagram"
            target="_blank"
            href={metadataInfo.socials && metadataInfo.socials.instagram}
          >
            <FaInstagram size={15} color="#fff" />
          </Icon>
        </div>
      </header>
    </Container>
  );
}

Header.defaultProps = {
  logo: 'abvaq',
};

Header.propTypes = {
  logo: PropTypes.string,
  menuitems: PropTypes.arrayOf(PropTypes.string).isRequired,
};
