import styled from 'styled-components';
import media from 'styled-media-query';

export const Page = styled.div`
  position: relative;

  ${media.lessThan('large')`
    margin-top: 70px;
  `}

  .filters {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;

    ${media.lessThan('large')`
      top: -50px;
    `}

    > div + div {
      margin-left: 16px;
    }
  }
`;
