export default {
  primary: '#366045',
  primaryDarken: '#21472E',
  primaryLighten: '#596C5E',

  secondary: '#FFCC2B',
  secondaryDarken: '#FF9C2B',
  secondaryLighten: 'rgba(255, 204, 43, 0.47)',

  black: '#000903',
  grey: '#999999 ',
  greyest: '#eee',

  white: '#fff',
  whiteGreen: '#f9fafb',
  whiteVariant1: '#F3F3F3',
  whiteVariant2: '#F3F5E7',

  danger: 'hsl(348, 100%, 61%)',
  success: 'hsl(141, 71%, 48%)',
  warning: 'hsl(48, 100%, 67%)',
};
