import { call, put, all, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';

import { metadataSuccess, metadataFailure } from './action';

export function* request({ payload }) {
  try {
    let { hostname } = window.location;

    if (hostname === 'localhost') hostname = 'www.abvaq.com.br';

    const response = yield call(api.get, `metadata/${hostname}`);

    yield put(metadataSuccess(response.data));
  } catch (err) {
    console.tron.log(err);
    yield put(metadataFailure());
  }
}

export default all([takeLatest('@metadata/REQUEST', request)]);
