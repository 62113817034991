export function setMenuAsOpen(data) {
  return {
    type: '@menu/OPEN',
    payload: { data },
  };
}

export function setMenuAsClose(data) {
  return {
    type: '@menu/CLOSE',
    payload: { data },
  };
}

export function setActiveItem(item) {
  return {
    type: '@menu/ACTIVE_ITEM',
    payload: item,
  };
}

export function requestItems(client_id) {
  return {
    type: '@menu/REQUEST_ITEMS',
    payload: client_id,
  };
}

export function successItems(items) {
  return {
    type: '@menu/SUCCESS_ITEMS',
    payload: items,
  };
}
