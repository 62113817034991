import React from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';
import { useSelector, useDispatch } from 'react-redux';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

import { setMenuAsClose, setActiveItem } from '~/store/modules/menu/action';

import { Container, Menu, MenuItem, Icon } from './styles';

function BigMenu({ children, isMobile }) {
  const dispatch = useDispatch();
  const menu = useSelector(state => state.menu);
  const metadataInfo = useSelector(state => state.metadatas.information);

  function handleMenuItemClick(item) {
    dispatch(setMenuAsClose({ open: false }));
    dispatch(setActiveItem({ item }));
  }

  return (
    <Container menuOpen={menu.open}>
      {isMobile && (
        <Menu>
          <ul>
            {metadataInfo.menu_items &&
              metadataInfo.menu_items.map(item => (
                <MenuItem
                  key={item.name}
                  isActive={item.active}
                  onClick={() => handleMenuItemClick(item.name)}
                >
                  <a href={item.url}>{item.prettyName}</a>
                </MenuItem>
              ))}
          </ul>

          <div className="socials">
            <Icon
              name="facebook"
              target="_blank"
              href={metadataInfo.socials && metadataInfo.socials.facebook}
            >
              <FaFacebookF size={20} color="#fff" />
            </Icon>
            <Icon
              name="instagram"
              target="_blank"
              href={metadataInfo.socials && metadataInfo.socials.instagram}
            >
              <FaInstagram size={20} color="#fff" />
            </Icon>
          </div>
        </Menu>
      )}

      {children}
    </Container>
  );
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 1170,
});

BigMenu.propTypes = {
  children: PropTypes.element.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default withSizes(mapSizesToProps)(BigMenu);
