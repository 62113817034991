import React from 'react';
import PropTypes from 'prop-types';

import { Container, Back } from './styles';

export default function Title({ title, subtitle, backBtn, mobileHide }) {
  return (
    <Container mobileHide={mobileHide}>
      <hgroup>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </hgroup>

      {backBtn && <Back to="/">voltar</Back>}
    </Container>
  );
}

Title.defaultProps = {
  backBtn: false,
  subtitle: '',
};

Title.propTypes = {
  backBtn: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};
