import React from 'react';
import PropTypes from 'prop-types';

import { Container, Item } from './styles';

export default function TableAction({
  categories,
  activeCategory,
  changeActiveCategory,
}) {
  return (
    <Container>
      {categories.map(category => (
        <Item
          key={category.id}
          active={category.title === activeCategory}
          onClick={() => changeActiveCategory(category)}
        >
          {category.title}
        </Item>
      ))}
    </Container>
  );
}

TableAction.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ).isRequired,
  activeCategory: PropTypes.string.isRequired,
  changeActiveCategory: PropTypes.func.isRequired,
};
